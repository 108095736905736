<template>
  <div class="d-md-flex w-md-100">
    <context-menu ref="context">
      <b-button
        block
        :variant="selectedProfile.name === profile.name ? 'primary' : 'light'"
        v-for="(profile, index) in profiles"
        :key="index"
        @click="handleSelectProfile(profile)"
        class="text-center d-md-flex align-items-center px-2"
      >
        <b-icon icon="lock" height="25" />
        <strong class="ml-2">{{ profile.name }}</strong>
      </b-button>
    </context-menu>
    <div class="indicators w-100">
      <div class="d-md-flex mt-2 align-items-center justify-content-between">
        <h5>
          <b-form-input
            v-model="selectedProfile.name"
            :disabled="!selectedProfile.editable || is_provider"
            @change="handleUpdateProfileName"
          />
        </h5>

        <section v-if="!is_provider">
          <a
            v-if="selectedProfile.editable"
            class="icon-action mr-4"
            v-b-popover.hover.top="'Excluir'"
            v-b-modal.delete-profile
          >
            <b-icon class="h6 pointer mb-0" icon="trash" />
          </a>
          <b-button
            @click="
              $bvModal.show('add-new-profile-permission');
              this.$refs.context.close();
            "
            class="btn-add"
          >
            <b-icon icon="plus-circle" class="mr-2" />
            <span>Adicionar perfil</span>
          </b-button>
        </section>
      </div>
      <div class="indicators-list w-md-100">
        <div class="my-2 d-flex">
          <b-button
            size="sm"
            v-for="(menu, index) in selectedProfile.permissions_tree"
            :key="index"
            @click="handleSelectMenu(menu)"
            class="mr-2 h-auto"
            :block="is_mobile"
            :variant="selectedMenu === menu ? 'primary' : 'light'"
            :disabled="!menu.children"
          >
            {{ menu.text }}
          </b-button>
        </div>
        <template v-for="(selected, index) in selectedMenu.children">
          <b-card
            :key="index"
            v-if="!selected.hideInMenu"
            class="
              w-md-100
              d-md-flex
              align-items-center
              justify-content-between
              py-2
              px-4
              flex-row
              mb-2
            "
            no-body
          >
            <strong class="w-md-100">{{ selected.text }}</strong>
            <div class="d-md-flex">
              <b-form-checkbox
                :disabled="is_provider"
                v-model="prm.$checked"
                name="check-button"
                v-for="(prm, i) in selected.children"
                :key="i"
                @change="handleChangePermission(prm, selected)"
              >
                {{ prm.text }}
              </b-form-checkbox>
            </div>
          </b-card>
        </template>
      </div>
    </div>
    <b-modal
      id="add-new-profile-permission"
      centered
      size="md"
      no-close-on-backdrop
      title="Novo perfil"
    >
      <b-form-group label="Nome">
        <b-form-input v-model="profileName" />
      </b-form-group>
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="close()">Cancelar</b-button>
        <b-button variant="primary" @click="handleAddProfile()"
          >Adicionar</b-button
        >
      </template>
    </b-modal>
    <b-modal id="delete-profile" centered size="sm">
      <template #modal-header> <h5>Cuidado!</h5></template>
      <p>
        Você apagará o perfil <strong> {{ selectedProfile.name }}</strong
        >. <br />
        Essa ação não poderá ser desfeita, deseja continuar?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="handleDeleteProfile()">Sim</b-button>
        <b-button variant="primary" @click="close()">Não</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { menuData } from "@/constants/menu";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      profileName: "",
      selectedProfile: "",
      selectedMenu: "",
      checked: "",
      menus: menuData,
      profiles: [],
    };
  },
  methods: {
    ...mapActions([
      "add_profile",
      "delete_profile",
      "edit_profile",
      "get_all_profile",
    ]),
    handleSelectProfile(profile) {
      this.selectedProfile = profile;
      this.selectedMenu = this.selectedProfile.permissions_tree[0];
      this.$refs.context.close();
    },
    handleAddProfile() {
      this.add_profile({
        name: this.profileName,
        name_plural: `${this.profileName}s`,
      }).then(({ status }) => {
        if (status === 201) {
          this.getProfiles();
          this.$bvModal.hide("add-new-profile-permission");
          this.profileName = "";
        }
      });
    },
    handleSelectMenu(menu) {
      this.selectedMenu = menu;
    },
    getProfiles() {
      this.get_all_profile().then(({ data }) => {
        this.profiles = data.results;
        this.selectedProfile = this.profiles[0];
        this.selectedMenu = this.selectedProfile.permissions_tree[0];
      });
    },
    handleDeleteProfile() {
      this.delete_profile(this.selectedProfile.id).then(() => {
        this.$bvModal.hide("delete-profile");
        this.getProfiles();
      });
    },
    handleChangePermission({ $checked, name }) {
      let permissions = this.selectedProfile.permissions;
      if ($checked) {
        permissions.push({
          name,
        });
      } else {
        const index = permissions.indexOf(
          permissions.find((p) => p.name === name)
        );
        permissions.splice(index, 1);
      }
      this.selectedProfile.permissions = permissions;

      this.edit_profile({ ...this.selectedProfile });
    },
    handleUpdateProfileName() {
      const { name, id } = this.selectedProfile;
      this.edit_profile({ name, name_plural: name, id });
    },
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile"]),
  },
  mounted() {
    this.getProfiles();
  },
};
</script>
